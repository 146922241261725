var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "iconfont icon-fanhui" }),
                  _vm._v("返回 ")
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "partition-area" },
        [
          _c(
            "el-form",
            {
              ref: "templateDetail",
              attrs: {
                rules: _vm.rules,
                model: _vm.templateDetail,
                "label-width": "170px"
              }
            },
            [
              _c("div", { staticClass: "detail-area" }, [
                _c("div", { staticClass: "area-title" }, [
                  _c("p", { staticClass: "title" }, [
                    _vm._v(" 服务费模板信息 ")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "detail-inner" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "coreId", label: "核心企业：" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.templateDetail.coreName) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "占比：",
                                  prop: "coreProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.templateDetail.coreProportion) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.templateDetail.coreAccount
                      ? _c(
                          "el-row",
                          { attrs: { gutter: 80 } },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "账户号码：" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.isEffectiveCommon(
                                            _vm.templateDetail.coreAccount
                                          )
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "10px" }
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            attrs: { disabled: "", label: "1" },
                                            model: {
                                              value: _vm.defaultAccount,
                                              callback: function($$v) {
                                                _vm.defaultAccount = $$v
                                              },
                                              expression: "defaultAccount"
                                            }
                                          },
                                          [_vm._v(_vm._s(""))]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "运营方：",
                                  prop: "channelName"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.templateDetail.channelName) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "占比：",
                                  prop: "channelProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.templateDetail.channelProportion
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "账户号码：" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.templateDetail.channelAccount
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { disabled: "", label: "2" },
                                        model: {
                                          value: _vm.defaultAccount,
                                          callback: function($$v) {
                                            _vm.defaultAccount = $$v
                                          },
                                          expression: "defaultAccount"
                                        }
                                      },
                                      [_vm._v(_vm._s(""))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "capId", label: "资金方：" } },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.templateDetail.capName) + " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "占比：",
                                  prop: "capProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.templateDetail.capProportion) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "账户号码：" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.templateDetail.capAccount
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { disabled: "", label: "3" },
                                        model: {
                                          value: _vm.defaultAccount,
                                          callback: function($$v) {
                                            _vm.defaultAccount = $$v
                                          },
                                          expression: "defaultAccount"
                                        }
                                      },
                                      [_vm._v(_vm._s(""))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台技术占比：",
                                  prop: "tenantTechProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.templateDetail.tenantTechProportion
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台运营占比：",
                                  prop: "tenantSystemProportion"
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.templateDetail.tenantSystemProportion
                                    ) +
                                    "% "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      { attrs: { gutter: 80 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "账户号码：" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.templateDetail.tenantTechAccount
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { disabled: "", label: "4" },
                                        model: {
                                          value: _vm.defaultAccount,
                                          callback: function($$v) {
                                            _vm.defaultAccount = $$v
                                          },
                                          expression: "defaultAccount"
                                        }
                                      },
                                      [_vm._v(_vm._s(""))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "账户号码：" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.templateDetail.tenantSystemAccount
                                      )
                                    ) +
                                    " "
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "10px" } },
                                  [
                                    _c(
                                      "el-radio",
                                      {
                                        attrs: { disabled: "", label: "5" },
                                        model: {
                                          value: _vm.defaultAccount,
                                          callback: function($$v) {
                                            _vm.defaultAccount = $$v
                                          },
                                          expression: "defaultAccount"
                                        }
                                      },
                                      [_vm._v(_vm._s(""))]
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }