<!-- 服务费模板详情 -->
<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button @click="$router.back()">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div class="partition-area">
      <el-form ref="templateDetail" :rules="rules" :model="templateDetail" label-width="170px">
        <div class="detail-area">
          <div class="area-title">
            <p class="title">
              服务费模板信息
            </p>
          </div>
          <div class="detail-inner">
            <!-- 核心企业 -->
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="coreId" label="核心企业：">
                  {{ templateDetail.coreName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比：" prop="coreProportion">
                  {{ templateDetail.coreProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="templateDetail.coreAccount" :gutter="80">
              <el-col :span="12">
                <el-form-item label="账户号码：">
                  {{ utils.isEffectiveCommon(templateDetail.coreAccount) }}
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" disabled label="1">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- 运营方 -->
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="运营方：" prop="channelName">
                  {{ templateDetail.channelName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比：" prop="channelProportion">
                  {{ templateDetail.channelProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="账户号码：">
                  {{ utils.isEffectiveCommon(templateDetail.channelAccount) }}
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" disabled label="2">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item prop="capId" label="资金方：">
                  {{ templateDetail.capName }}
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="占比：" prop="capProportion">
                  {{ templateDetail.capProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="账户号码：">
                  {{ utils.isEffectiveCommon(templateDetail.capAccount) }}
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" disabled label="3">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="平台技术占比：" prop="tenantTechProportion">
                  {{ templateDetail.tenantTechProportion }}%
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="平台运营占比：" prop="tenantSystemProportion">
                  {{ templateDetail.tenantSystemProportion }}%
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="80">
              <el-col :span="12">
                <el-form-item label="账户号码：">
                  {{ utils.isEffectiveCommon(templateDetail.tenantTechAccount) }}
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" disabled label="4">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="账户号码：">
                  {{ utils.isEffectiveCommon(templateDetail.tenantSystemAccount) }}
                  <span style="margin-left: 10px;">
                    <el-radio v-model="defaultAccount" disabled label="5">{{ '' }}</el-radio>
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
  </el-main>
</template>
<script>
import axios from 'axios'
export default {
  components: {
  },
  data () {
   return {
      isShow: false,
      templateDetail: {
        templateCode: '',
        tenantTechProportion: '',
        tenantSystemProportion: '',
        coreId: '',
        coreCode: '',
        coreName: '',
        coreProportion: '',
        capId: '',
        capCode: '',
        capName: '',
        capProportion: '',
        channelId: '',
        channelCode: '',
        channelName: '',
        channelProportion: '',
        coreAccount: '',
        coreAccountName: '',
        channelAccount: '',
        channelAccountName: '',
        capAccount: '',
        capAccountName: '',
        tenantTechAccount:'',
        tenantTechAccountName:'',
        tenantSystemAccount:'',
        tenantSystemAccountName:'',
        id: null
      },
     defaultAccount: '',
      loading: false ,
      rules: {},
      capList: [],
      coreList: [],
      create_op: false,
      edit_op: false,
      detail_op: false,
  }
  },
  watch: {},
  created () {
    this.id = this.$route.query.id
    this.getDataById()

  },
  methods: {
    getDataById(){
     console.log(this.$route.params.id)
     this.api.serviceCharge.getTemplateDetail({id:this.id}).then(res => {
       const data = res.data.data || {}
        this.templateDetail = data
       this.defaultAccount = String(data.defaultAccount)
      })
    },
    getWidth () {
      this.conWidth.width = window.innerHeight - 120 + 'px'
    },
    created () {
      window.addEventListener('resize', this.getWidth)
      this.getWidth()
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:80%;
  }
</style>
