import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=3003308e&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=3003308e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3003308e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mfs/build/project-ics/dev/ics-tenant-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3003308e')) {
      api.createRecord('3003308e', component.options)
    } else {
      api.reload('3003308e', component.options)
    }
    module.hot.accept("./detail.vue?vue&type=template&id=3003308e&scoped=true&", function () {
      api.rerender('3003308e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/serviceChargeManagement/template/detail.vue"
export default component.exports